<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :columns="columns"
            :get_table_list="this.get_table_list"
            :slot_table_list="['address','visit_total','signboard','shelves','materials','freezing','box']"
            ref="list"
            :submit_preprocessing="submit_preprocessing"
        >	

			<template slot="right_btn">
				<a v-if="this.$store.state.page_auth.export" @click="report()">
					<a-button class="a_btn">导出</a-button>
				</a>
            </template>

			<template slot="visit_total" slot-scope="record">
				<a @click="see(record)">{{record.text}}</a>
			</template>
				<template
                slot="address"
                slot-scope="data"
            >{{data.record.province + data.record.city + data.record.district + data.record.address}}</template>

			<template slot="signboard" slot-scope="record">
				<a @click="imitation(record,'signboard')">{{record.text}}</a>
			</template>
			<template slot="shelves" slot-scope="record">
				<a @click="imitation(record,'shelves')">{{record.text}}</a>
			</template>
			<template slot="materials" slot-scope="record">
				<a @click="imitation(record,'materials')">{{record.text}}</a>
			</template>
			<template slot="freezing" slot-scope="record">
				<a @click="imitation(record,'freezing')">{{record.text}}</a>
			</template>
			<template slot="box" slot-scope="record">
				<a @click="imitation(record,'box')">{{record.text}}</a>
			</template>

        </TableList>


		<PopTableList
            rowKey="id"
            ref="pop_table_list"
            :is_created_get_list="false"
            :columns="columns_pop"
            :get_table_list="get_table_list_pop"
            :visible.sync="visible"
            :slot_table_list="['address']"
            @submit="pop_table_submit"
        >
            
        </PopTableList>
		
    </div>
</template>

<script>

import TableList from "@/components/TableList";
import { customerPhoto,photoInfo } from "@/api/customer";
import PopTableList from "@/components/PopTableList";
const columns = [
	{
        title: "客户名称",
        dataIndex: "name",
    },
    {
        title: "客户地址",
		dataIndex: "address",
		scopedSlots: {
            customRender: "address"
        }
    },
    {
        title: "拜访次数",
		dataIndex: "visit_total",
		scopedSlots: {
            customRender: "visit_total"
        }
    },
    {
        title: "店招",
		dataIndex: "signboard",
		scopedSlots: {
            customRender: "signboard",
        }
    },
    {
        title: "货架陈列",
		dataIndex: "shelves",
		scopedSlots: {
            customRender: "shelves",
        }
    },
    {
        title: "广宣物料堆箱",
		dataIndex: "materials",
		scopedSlots: {
            customRender: "materials",
        }
    },
	{
        title: "冰冻化",
		dataIndex: "freezing",
		scopedSlots: {
            customRender: "freezing",
        }
	},
	{
        title: "堆箱",
		dataIndex: "box",
		scopedSlots: {
            customRender: "box",
        }
    },
];


const columns_pop = [
	{
        title: "拜访时间",
        dataIndex: "arrive_time",
    },
    {
        title: "拜访姓名",
		dataIndex: "username",
    },
    {
        title: "部门",
        dataIndex: "department",
    },
    {
        title: "店招",
        dataIndex: "signboard",
    },
    {
        title: "货架陈列",
        dataIndex: "shelves",
    },
    {
        title: "广宣物料堆箱",
        dataIndex: "materials",
    },
	{
        title: "冰冻化",
        dataIndex: "freezing",
	},
	{
        title: "堆箱",
        dataIndex: "box",
    }
];



export default {
    name: "Indexphoto",
    components: {
		TableList,
		PopTableList,
    },
    data() {
        return {
			get_table_list: customerPhoto,
			get_table_list_pop:photoInfo,
            submit_preprocessing: {
                array_to_string: ['department']
            },
            order_details: {
                goods: [],
            },
			columns,
			columns_pop,
			visible:false,
            form_data_seo: {
                list: [
					
					{
                        type: "tree-select",
                        name: "department",
                        title: "部门",
                        options: {},
                        treeData: [],
                        multiple: true
                    },
					/* {
                        type: "select",
                        name: "role_id",
                        title: "岗位职务",
                        mode: "default",
                        options: {},
                        list: []
					}, */
					{
                        type: "text",
                        name: "keyword",
                        title: "客户",
                        placeholder: "客户名称/地址/ID",
                        options: {}
					},
                    {
                        type: "text",
                        name: "username",
                        title: "姓名",
                        placeholder: '姓名/账号/工号/手机号',
                        mode: "default",
                        options: {},
                        list: []
					},
					{
						type: "select",
						name: "user_status",
						title: "在职状态",
						mode: "default",
						options: {},
						list: this.$config.user_status_list
					},
					{
                        type: "range_date",
                        name: "range_date",
                        title: "时间",
                        options: {},
                        start: {
                            name: 'start_time'
                        },
                        end: {
                            name: 'end_time'
                        },
                    },
                ],
                ...this.$config.form_data_seo
            },
            form_data: {}
        };
    },
    async created() {

		this.$method.get_department().then(res => {
            this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'department', 'treeData', res)
		});

		this.$method.get_role().then(res => {
            this.form_data_seo.list.forEach(item => {
                if (item.name == "role_id") {
                    item.list = [...this.$config.status_all, ...res];
                }
            });
		});
    },
    methods: {
		see(record){
			this.visible = true;

			this.$nextTick(res => {
				this.$refs.pop_table_list.set_data('fixed_seo_data', {
					customer_id:record.record.customer_id,
					start_time:this.$refs.list.seo_data.start_time,
					end_time:this.$refs.list.seo_data.end_time,
					...this.$refs.list.seo_data
				})
				this.$refs.pop_table_list.get_list();
			})
		},
		report() {
			this.$router.push({
				path:"/report/modify_report",
				query:{
					report_key:"custom_visit_customer",
					...this.$refs.list.seo_data
				}
			});
		},
		imitation({record},type){
			this.$router.push({
				path:"/imitation/index",
				query:{
					customer_id:record.customer_id,
					data_type:type,
					...this.$refs.list.seo_data
				}
			});
		},

		pop_table_submit(){
			this.visible = false;
		},
    }
};
</script>

<style lang="less">

</style>